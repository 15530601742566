// Here you can add other styles
@media print {
  body * {
    visibility: hidden;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

  #section-to-print {
    top: 0px;
    left: 0px;
    width: 100%;
    position: fixed;
  }

  .table-print {
    border: 1px solid #000;
    padding: 20px;
    font-size: larger;
  }

  .title {
    border: 1px solid #000;
  }
}

.table-print {
  border: 1px solid #000;
  padding: 20px;
  font-size: larger;
}

.title {
  border: 1px solid #000;
}

// .fc-col-header {
//   width: 100% !important;
//   height: 100% !important;
// }

// .fc-scrollgrid-sync-table {
//   width: auto !important;
// }
